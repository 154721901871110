import { Box, Container, Hidden } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import classNames from 'classnames';
import { Link } from 'gatsby';
import throttle from 'lodash.throttle';
import Slider from 'react-slick';
import styles from '../../pages/campaign/20240930_campaign.module.scss';
import { Layout } from '../../components';
import UseInstallments from '../../components/Landing/UseInstallments';
import LinearCTAButton from '../../components/LinearCTAButton';
import JulyAppDownload from '../../components/Merchant/Campaign/2022AAA_202211/JulyAppDownload';
import SectionCampaignFooter from '../../components/Merchant/Campaign/TravelsSummerVol1/SectionCampaignFooter';
import { SITE_METADATA } from '../../constants';
import PaidyLogoImg from '../../images/merchant/campaign/202304_6pay/paidy-logo.png';
import BannerImg from '../../images/campaign/20240930_campaign/banner.jpg';
import BannerSpImg from '../../images/campaign/20240930_campaign/banner-sp.jpg';
import SixPayUpsellImg from '../../images/campaign/20240815_Lottery/6pay_upsell.png';
import QrAndBadgesSwitch from '../../components/QrAndBadgesSwitch';
import CartImg from '../../images/6pay/cart.png';
import PayStep3Img from '../../images/6pay/pay-step-3.png';
import PayStep4Img from '../../images/campaign/20240701_travel/6pay/step4.svg';
import MerchantsGridWithAccordion from '../../components/MerchantsGridWithAccordion';
import {
  TAGS,
  MERCHANT_GROUPED_BY_CATEGORY,
  FIXES_TAGS,
  MERCHANTS_GROUPED_BY_FIXED_TAGS,
  MERCHANTS,
} from '../../components/Campaign/20240930_campaign/MerchantsGridWithAccordionData';

const SEOProps = {
  title: SITE_METADATA.campaign_20240930.title,
  description: SITE_METADATA.campaign_20240930.description,
};

const LOGOS = [...Array(10).keys()].map((key) => ({
  key,
  // eslint-disable-next-line import/no-dynamic-require, security/detect-non-literal-require
  src: require(`../../images/campaign/20240930_campaign/merchant-logos-header/merchant-${key}.png`),
}));

const NAVS = ['キャンペーン概要', '3・6・12回あと払い', '対象ショップ'];

const TERMS = [
  {
    title: 'キャンペーン期間',
    description: '2024年12月6日（金）00:00～2025年1月6日（月）23:59',
  },
  {
    title: 'キャンペーン概要・特典',
    description: (
      <>
        キャンペーン期間中に対象ショップの決済画面であと払い（ペイディ）を選択し、3・6・12回あと払いのいずれかをそのショップで初めて利用すると10％キャッシュバック。{' '}
        <br />
        <br />
        <small>
          ※1店舗につき最大1,000円。3・6・12回あと払いをそれぞれ異なる店舗で利用した場合は、最大3,000円となります。
          <br />
          ※選択できる支払い回数は加盟店により異なります。
          <br />
          ※3・6・12回あと払いのご利用には本人確認が必要です。
        </small>
        <br />
        <br />
        なお、下記の4店舗のみ、一括払いで購入後、ペイディアプリから3・6・12回あと払いに変更したお支払いもキャンペーン対象となります。その際、当月中に必ずペイディアプリから3・6・12回あと払いに変更してください。
        <br />
        <ul>
          <li>17LIVE</li>
          <li>AVIOT ONLINE MALL</li>
          <li>mederi</li>
          <li>ふるさとチョイス</li>
        </ul>
        上記の4店舗以外は、一括払いで購入後、ペイディアプリから3・6・12回あと払いに変更した場合はキャンペーン対象外となりますのでご注意ください。
        <br />
        <br />
        <small>
          キャンペーン終了後、2025年7月末までに特典分がキャッシュバックされる予定です。
          <br />
          キャッシュバックはペイディアプリおよびMyPaidyに反映されます。決済画面には反映されません。
        </small>
      </>
    ),
  },
  {
    title: 'キャンペーン対象',
    description:
      '対象ショップの決済画面であと払い（ペイディ）を選択し、3・6・12回あと払いをそのショップで初めてご利用のお客様',
  },
  {
    title: 'ご注意事項',
    description: (
      <ul>
        <li>
          同期間に開催中の他のキャンペーンと併せて月間当選上限額は10万円になります。
        </li>
        <li>
          キャッシュバックは、決済画面でペイディを選択した場合のみ対象です。ペイディカードの利用は対象外となります。
        </li>
        <li>
          キャンペーン終了後2025年7月31日までに特典分がキャッシュバックされる予定です。また予定日より遅れることもありますので、予めご了承ください。
        </li>
        <li>
          キャッシュバック付与のタイミングで、ペイディの利用が無い場合、付与日以降でペイディをご利用いただいた際に、該当キャッシュバックが付与されます。あらかじめご了承ください。
        </li>
        <li>
          株式会社Paidyは本キャンペーンを、キャンペーン期間であっても予告なく変更または終了する場合がございます。
        </li>
        <li>
          不正と判断された場合やキャンセルがあった場合は、キャッシュバックが取り消される場合がございます。
        </li>
        <li>
          その他条件はペイディ
          <OutboundLink
            href="https://terms.paidy.com/cashback/"
            target="_blank"
            rel="noopener noreferrer"
          >
            キャッシュバック及びクーポン利用規約
          </OutboundLink>
          に準じます。
        </li>
        <li>
          ペイディに関するお問い合わせは
          <OutboundLink
            href="https://cs.paidy.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            ペイディヘルプページ
          </OutboundLink>
          へご連絡ください。
        </li>
      </ul>
    ),
  },
];

const SIXPAY = [
  {
    ImgComponent: () => <QrAndBadgesSwitch />,
    figCaption: 'ペイディアプリから本人確認する。',
  },
  {
    ImgComponent: () => (
      <img alt="cart" src={CartImg} width={184} height={184} />
    ),
    figCaption: 'ショップで欲しい商品をカートに入れる。',
  },
  {
    ImgComponent: () => (
      <img alt="pay-step-3" src={PayStep3Img} width={184} height={184} />
    ),
    figCaption:
      'お支払い方法で「あと払い（ペイディ）」を選択して、メールアドレスと携帯電話番号を入力する｡',
  },
  {
    ImgComponent: () => (
      <img alt="pay-step-4" src={PayStep4Img} width={184} height={184} />
    ),
    figCaption: 'ご希望のお支払い回数を選んで完了。',
  },
];

const useScrollAndSetActiveSection = (navs = NAVS) => {
  const [activeSection, setActiveSection] = useState(navs[0]);

  const onScroll = useCallback(() => {
    if (!document) {
      return;
    }

    // eslint-disable-next-line no-restricted-syntax, no-plusplus
    for (let i = 0; i < navs.length; i++) {
      const nav = navs[i];
      const rect = document.getElementById(nav).getBoundingClientRect();
      const isOnScreen = rect.top <= 64 && rect.bottom >= 64;

      if (isOnScreen) {
        setActiveSection(nav);
      }
    }
  }, [navs]);

  useEffect(() => {
    const throttledScroll = throttle(onScroll, 200);

    if (window) {
      window.addEventListener('scroll', throttledScroll, true);
    }

    return () => {
      if (window) {
        window.removeEventListener('scroll', throttledScroll);
      }
    };
  }, [onScroll]);

  return activeSection;
};

const SLIDER_SETTINGS = {
  classNames: 'slider variable-width',
  dots: false,
  infinite: true,
  centerPadding: '2px',
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 2000,
  cssEase: 'linear',
  variableWidth: true,
};

export default function Campaign202310NPay() {
  const activeSection = useScrollAndSetActiveSection(NAVS);

  return (
    <Layout SEOProps={SEOProps} hasNoHeader hasNoFooter>
      <Box className={styles.page}>
        <header className={styles.sectionHeader}>
          <img alt="paidy-logo" width={114} height={45} src={PaidyLogoImg} />
        </header>

        <section className={styles.sectionBanner}>
          <Hidden xsDown>
            <img alt="banner" height={522} width={1440} src={BannerImg} />
          </Hidden>
          <Hidden smUp>
            <img alt="banner-sp" width="100%" src={BannerSpImg} />
          </Hidden>
        </section>

        <section className={styles.sectionMerchantLogos}>
          <Hidden xsDown>
            <p className={styles.title}>対象加盟店一例</p>
            <Box className={styles.rows}>
              <Box
                display="flex"
                flex={1}
                justifyContent="center"
                flexWrap="wrap"
              >
                {LOGOS.slice(0, 5).map((logo) => (
                  <Box key={logo.key} px={1}>
                    <img
                      alt={logo.key}
                      src={logo.src}
                      width={152}
                      height={50}
                    />
                  </Box>
                ))}
              </Box>
              <Box
                display="flex"
                flex={1}
                justifyContent="center"
                flexWrap="wrap"
              >
                {LOGOS.slice(5).map((logo) => (
                  <Box key={logo.key} px={1}>
                    <img
                      alt={logo.key}
                      src={logo.src}
                      width={152}
                      height={50}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </Hidden>

          <Hidden smUp>
            <Box width="100%" height={80}>
              <Slider {...SLIDER_SETTINGS}>
                {LOGOS.map((logo) => (
                  <div style={{ width: 140 }} key={logo.key}>
                    <img
                      alt={logo.key}
                      src={logo.src}
                      width={140}
                      height={46}
                    />
                  </div>
                ))}
              </Slider>
            </Box>
          </Hidden>
          <LinearCTAButton
            text="対象サービスを見る"
            to={`#${NAVS[2]}`}
            className={styles.button}
          />
        </section>

        <nav className={styles.sectionNav}>
          {NAVS.map((nav) => (
            <Box
              key={nav}
              className={classNames(styles.item, {
                [styles.active]: nav === activeSection,
              })}
            >
              <Link to={`#${nav}`}>{nav}</Link>
            </Box>
          ))}
        </nav>

        <section className={styles.sectionTerms} id={NAVS[0]}>
          <Container className={styles.container}>
            {TERMS.map((term) => (
              <Box key={term.title}>
                <p className={styles.title}>{term.title}</p>
                <p className={styles.description}>{term.description}</p>
                {term.notes && <p className={styles.notes}>{term.notes}</p>}
              </Box>
            ))}
          </Container>
        </section>

        <section className={styles.section6Pay} id={NAVS[1]}>
          <Box className={styles.top}>
            <>
              <Hidden xsDown>
                <img
                  alt="6pay"
                  width={367}
                  height={400}
                  src={SixPayUpsellImg}
                />
              </Hidden>
              <Hidden smUp>
                <img
                  alt="6pay"
                  width={247}
                  height={268}
                  src={SixPayUpsellImg}
                />
              </Hidden>
            </>
            <Box className={styles.texts}>
              <p className={styles.title}>
                高額商品も、分割手数料無料*の
                <br />
                3・6・12回あと払いでかしこく購入。
                <br className={styles.hideOnMobile} />
                キャッシュバックでお得に 始めるチャンス！
              </p>
              <p className={styles.text}>
                ペイディなら、3・6・12回あと払いが分割手数料無料*。お支払い総額はそのまま、月々のお支払い金額を調整できるので、高額商品もかしこく購入できます。
                このチャンスに、3・6・12回あと払いを利用してお得にお買い物を楽しみませんか？
              </p>
              <p className={styles.notes}>
                *口座振替・銀行振込のみ無料
                <br />
                ※3回あと払いは1回のお買い物につき3,000円以上、6回あと払いは1回のお買い物につき6,000円以上、12回あと払いは1回のお買い物につき12,000円以上のお支払いで利用可能です。
                <br />
                ※選択できる支払い回数は加盟店により異なります。
              </p>
            </Box>
          </Box>
          <UseInstallments
            title="３・6・12回あと払いのご利用方法"
            data={SIXPAY}
            noArrows
          />
        </section>

        <section id={NAVS[2]}>
          <section className={styles.sectionMerchants}>
            <p className={styles.title}>キャンペーン対象ショップ</p>
            <MerchantsGridWithAccordion
              notShowButtonBottom
              tags={TAGS}
              merchantGroupedByCategory={MERCHANT_GROUPED_BY_CATEGORY}
              fixesTags={FIXES_TAGS}
              merchantsGroupedByFixedTags={MERCHANTS_GROUPED_BY_FIXED_TAGS}
              merchants={MERCHANTS}
              legacyMerchantsList={false}
              customClassFilterButtons={styles.sectionMerchantsFilterButtons}
            />
          </section>
        </section>

        <JulyAppDownload />

        <SectionCampaignFooter isThemeBlack />
      </Box>
    </Layout>
  );
}
