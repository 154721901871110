/* eslint-disable import/no-extraneous-dependencies */
export const MERCHANTS = {
  buyma: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-buyma.png'),
    destinationURL: 'https://www.buyma.com/?af=987',
    period: [3, 6],
  },
  rakuten: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-rakuten.png'),
    destinationURL:
      'https://fril.jp/?srsltid=AfmBOoqreft_9ftr8KXpMUJTCRtQ-TsPGyL5tq1UqXIxXgyPNRlmL9Ab',
    period: [3, 6],
  },
  'atmos-blue': {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-atmos-blue.png'),
    destinationURL: 'https://www.atmos-tokyo.com/',
    period: [3],
  },
  yz: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-yz.png'),
    destinationURL: 'https://yz-store.com/',
    period: [3, 6],
  },
  ur: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-ur.png'),
    destinationURL: 'http://www.urban-research.jp/',
    period: [3, 6],
  },
  newera: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-newera.png'),
    destinationURL: 'https://www.neweracap.jp/',
    period: [3],
  },
  'ralph-lauren': {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-ralph-lauren.png'),
    destinationURL: 'https://www.ralphlauren.co.jp/',
    period: [3, 6],
  },
  'taylor-made': {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-taylor-made.png'),
    destinationURL:
      'https://www.taylormadegolf.jp/home?utm_source=Paidy&utm_medium=CP&utm_campaign=0815-0831',
    period: [3, 6, 12],
  },
  'atmos-pink': {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/atmos-pink.png'),
    destinationURL: 'https://www.atmos-pink.com/',
    period: [3],
  },
  morecontact: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-morecontact.png'),
    destinationURL: 'https://morecon.jp/',
    period: [3],
  },
  dinos: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-dinos.png'),
    destinationURL: 'https://www.dinos.co.jp/',
    period: [3, 6, 12],
  },
  morecos: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-morecos.png'),
    destinationURL: 'https://morecos.hmv.co.jp/',
    period: [3],
  },
  hoshino: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-hoshino.png'),
    destinationURL: 'https://hoshinoresorts.com/jp/',
    period: [3, 6],
  },
  sakura: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-sakura.png'),
    destinationURL: 'https://www.sakuratravel.jp/',
    period: [3, 6, 12],
  },
  asoview: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-asoview.png'),
    destinationURL: 'https://www.asoview.com/',
    period: [3],
  },
  apa: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-apa.png'),
    destinationURL: 'https://www.apahotel.com/',
    period: [3, 6],
  },
  airtrip: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/airtrip.png'),
    destinationURL:
      'https://www.airtrip.jp/?utm_source=airtrip.jp&utm_medium=web&utm_campaign=paidy_10thanniversarycampaign_from20240930',
    period: [3, 6],
  },
  spacemarket: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-spacemarket.png'),
    destinationURL: 'https://www.spacemarket.com/',
    period: [3],
  },
  busbookmark: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/busbookmark.png'),
    destinationURL: 'https://www.busbookmark.jp/',
    period: [3],
  },
  dmm: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-dmm.png'),
    destinationURL: 'https://www.dmm.com/',
    period: [3, 6, 12],
  },
  dlsite: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-dlsite.png'),
    destinationURL: 'https://www.dlsite.com/index.html',
    period: [3, 6],
  },
  hololive: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-hololive.png'),
    destinationURL: 'https://shop.hololivepro.com/ ',
    period: [3, 6],
  },
  soundhouse: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-soundhouse.png'),
    destinationURL: 'https://www.soundhouse.co.jp/',
    period: [3, 6, 12],
  },
  i7live: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-i7live.png'),
    destinationURL: 'https://jp.17.live/',
    period: [3, 6, 12],
  },
  mobage: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-mobage.png'),
    destinationURL: 'https://www.mbga.jp/',
    period: [3],
  },
  hmvbooks: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/hmvbooks.png'),
    destinationURL: 'https://www.hmv.co.jp/',
    period: [3],
  },
  kojima: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-kojima.png'),
    destinationURL: 'https://www.kojima.net/ec/index.html',
    period: [3, 6, 12],
  },
  geo: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/geo.png'),
    destinationURL: 'https://ec.geo-online.co.jp/shop/',
    period: [3, 6, 12],
  },
  bicamera: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-bicamera.png'),
    destinationURL: 'https://www.biccamera.com/bc/main/',
    period: [3],
  },
  samsung: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-samsung.png'),
    destinationURL: 'https://www.samsung.com/jp/offer/',
    period: [3, 6, 12],
  },
  franc: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-franc.png'),
    destinationURL: 'https://francfranc.com/',
    period: [3, 6],
  },
  frontier: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/frontier.png'),
    destinationURL: 'https://www.frontier-direct.jp/',
    period: [3, 6, 12],
  },
  yamada: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-yamada.png'),
    destinationURL: 'https://www.yamada-denkiweb.com/',
    period: [3, 6, 12],
  },
  iris: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-iris.png'),
    destinationURL:
      'https://www.irisplaza.co.jp/index.php?&utm_source=paidy&utm_medium=Referral&utm_campaign=paidy_2409&utm_content=paidy_top',
    period: [3, 6, 12],
  },
  kurand: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/kurand.png'),
    destinationURL:
      'https://kurand.jp/?utm_source=klaviyo&utm_medium=ec__paidy&utm_campaign=27805___',
    period: [3, 6, 12],
  },
  peachjohn: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/peachjohn.png'),
    destinationURL: 'https://www.peachjohn.co.jp/shop/?bid=240930paidy',
    period: [3, 6, 12],
  },
  mashstore: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/mashstore.png'),
    destinationURL: 'https://app.mashstore.jp/qxSO/q5mdrrst',
    period: [3, 6, 12],
  },
  gelatopique: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/gelatopique.png'),
    destinationURL:
      'https://gelatopique.com/?utm_source=refferal&utm_medium=social&utm_campaign=Paidy_240930paidylp',
    period: [3, 6, 12],
  },
  drmartens: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/drmartens.png'),
    destinationURL: 'https://jp.drmartens.com/',
    period: [3],
  },
  snidel: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/snidel.png'),
    destinationURL:
      'https://snidel.com/?utm_source=refferal&utm_medium=social&utm_campaign=Paidy_240930paidylp',
    period: [3, 6, 12],
  },
  brookbrothers: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/brookbrothers.png'),
    destinationURL: 'https://www.brooksbrothers.co.jp/',
    period: [3, 6, 12],
  },
  'casio-online-store': {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/casio-online-store.png'),
    destinationURL:
      'https://www.casio.com/jp/online-store/?utm_source=paidy&utm_medium=referral&utm_campaign=onlinestore_paidy-cashback-campaign_202407_jp&utm_content=%7C%7Conlinestore%7C240702%7C%7Cpaidy%E3%82%AD%E3%83%A3%E3%83%83%E3%82%B7%E3%83%A5%E3%83%90%E3%83%83%E3%82%AF%E3%82%AD%E3%83%A3%E3%83%B3%E3%83%9A%E3%83%BC%E3%83%B3%7C%7C%7C',
    period: [3, 6, 12],
  },
  qoo10: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/qoo10.png'),
    destinationURL:
      'https://www.qoo10.jp/gmkt.inc/Special/Special.aspx?sid=162288&jaehuid=2026414795&gad_source=1&gclid=Cj0KCQjwiuC2BhDSARIsALOVfBLLx0MCikHLF2rFLOg06EuyNEtHsl7hAeGVdnWUPLG2jX7o5rROHA0aAuZOEALw_wcB',
    period: [3],
  },
  lensmode: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/lensmode.png'),
    destinationURL: 'https://www.lensmode.com/',
    period: [3],
  },
  vipliner: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/vipliner.png'),
    destinationURL: 'https://vipliner.biz/',
    period: [3, 6],
  },
  aviot: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/aviot.png'),
    destinationURL: 'https://shop.aviot.jp',
    period: [3, 6, 12],
  },
  furusato: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/furusato.png'),
    destinationURL:
      'https://www.furusato-tax.jp/?utm_source=Paidy&utm_medium=referral&utm_campaign=10th',
    period: [3],
  },
  mederi: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/mederi.png'),
    destinationURL: 'https://mederi.jp/',
    period: [3, 6, 12],
  },
  renta: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/renta.png'),
    destinationURL: 'https://renta.papy.co.jp/',
    period: [3],
  },
};

export const MERCHANT_GROUPED_BY_CATEGORY = [
  {
    categoryValue: 1,
    merchants: [
      MERCHANTS.buyma,
      MERCHANTS.rakuten,
      MERCHANTS['atmos-blue'],
      MERCHANTS.newera,
      MERCHANTS['atmos-pink'],
      MERCHANTS.ur,
      MERCHANTS.yz,
      MERCHANTS.peachjohn,
      MERCHANTS['ralph-lauren'],
      MERCHANTS.mashstore,
      MERCHANTS.gelatopique,
      MERCHANTS.drmartens,
      MERCHANTS['taylor-made'],
      MERCHANTS.snidel,
      MERCHANTS.brookbrothers,
      MERCHANTS['casio-online-store'],
      MERCHANTS.qoo10,
      MERCHANTS.morecontact,
      MERCHANTS.dinos,
    ],
  },
  {
    categoryValue: 2,
    merchants: [
      MERCHANTS.qoo10,
      MERCHANTS.morecontact,
      MERCHANTS.lensmode,
      MERCHANTS.morecos,
      MERCHANTS.buyma,
      MERCHANTS.peachjohn,
    ],
  },
  {
    categoryValue: 3,
    merchants: [
      MERCHANTS.asoview,
      MERCHANTS.sakura,
      MERCHANTS.hoshino,
      MERCHANTS.apa,
      MERCHANTS.airtrip,
      MERCHANTS.busbookmark,
      MERCHANTS.vipliner,
      MERCHANTS.spacemarket,
    ],
  },
  {
    categoryValue: 4,
    merchants: [
      MERCHANTS.dmm,
      MERCHANTS.hololive,
      MERCHANTS.dlsite,
      MERCHANTS.mobage,
      MERCHANTS.i7live,
      MERCHANTS.aviot,
      MERCHANTS.hmvbooks,
      MERCHANTS.soundhouse,
      MERCHANTS.geo,
      MERCHANTS.bicamera,
      MERCHANTS.kojima,
      MERCHANTS.renta,
    ],
  },
  {
    categoryValue: 5,
    merchants: [
      MERCHANTS.samsung,
      MERCHANTS.soundhouse,
      MERCHANTS.geo,
      MERCHANTS.bicamera,
      MERCHANTS.kojima,
      MERCHANTS.yamada,
      MERCHANTS.franc,
      MERCHANTS.dinos,
      MERCHANTS.frontier,
      MERCHANTS.iris,
      MERCHANTS.rakuten,
      MERCHANTS.aviot,
      MERCHANTS.mashstore,
      MERCHANTS['casio-online-store'],
    ],
  },
  {
    categoryValue: 6,
    merchants: [MERCHANTS.kurand, MERCHANTS.furusato],
  },
  {
    categoryValue: 7,
    merchants: [
      MERCHANTS.spacemarket,
      MERCHANTS.mederi,
      MERCHANTS.hololive,
      MERCHANTS.dlsite,
      MERCHANTS.asoview,
      MERCHANTS.yamada,
      MERCHANTS.iris,
      MERCHANTS['taylor-made'],
      MERCHANTS.furusato,
    ],
  },
];

export const TAGS = [
  { value: 0, label: 'すべて見る' },
  { value: 1, label: 'ファッション' },
  { value: 2, label: 'コスメ・カラコン' },
  { value: 3, label: '航空券・ ホテル・バス・チケット' },
  { value: 4, label: '動画・ライブ配信・ゲーム・ホビーグッズ' },
  { value: 5, label: '家電・インテリア雑貨' },
  { value: 6, label: 'グルメ・ギフト' },
  { value: 7, label: 'その他' },
];

export const FIXED_MERCHANTS = [];

export const MERCHANTS_GROUPED_BY_FIXED_TAGS = [];

export const FIXES_TAGS = [];
